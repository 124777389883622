.DZxMQq_form {
  margin-block-start: min(13.75rem, 48%);
}

.DZxMQq_form input {
  all: inherit;
  flex: 1;
  padding: 0;
}

.DZxMQq_form:after {
  content: "";
  background-color: var(--fg-color);
  border-radius: 2px;
  block-size: 2px;
  inline-size: 100%;
  margin-block-start: var(--grid-size);
  display: block;
}

.DZxMQq_content {
  text-transform: uppercase;
  -webkit-user-select: none;
  user-select: none;
  align-items: center;
  inline-size: 100%;
  font-size: 1.875rem;
  font-weight: 800;
  line-height: 1.0666;
  display: flex;
}

:root {
  --font-family: Futura, Futura-Medium, "Futura Medium", "Century Gothic", CenturyGothic, "Apple Gothic", AppleGothic, "URW Gothic L", "Avant Garde", sans-serif;
  --font-body-copy: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  --bg-color-ok: #4e7b26;
  --bg-color-warn: #d14900;
  --bg-color-alert: #c61023;
  --bg-color: var(--bg-color-ok);
  --fg-color: #fff;
  --grid-size: calc(8 / 16 * 1rem);
  --animate-duration: .4s;
  --animate-delay: .2s;
}

* {
  box-sizing: border-box;
}

html, body, .full-height {
  block-size: calc(100% + env(safe-area-inset-top)  + env(safe-area-inset-bottom));
}

html {
  background-color: var(--bg-color);
  color: var(--fg-color);
}

body {
  font-family: var(--font-family);
  background-color: var(--bg-color);
  margin: 0;
  transition: background-color 2s;
}

a {
  color: var(--bg-color-warn);
}

.visually-hidden {
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
  block-size: 1px !important;
  inline-size: 1px !important;
  margin: -1px !important;
  padding: 0 !important;
  position: absolute !important;
  overflow: hidden !important;
}

.no-transition {
  transition: none;
}

:root {
  --animate-duration: 1s;
  --animate-delay: 1s;
  --animate-repeat: 1;
}

.animate__animated {
  animation-duration: 1s;
  -webkit-animation-duration: var(--animate-duration);
  animation-duration: var(--animate-duration);
  animation-fill-mode: both;
}

@media print, (prefers-reduced-motion: reduce) {
  .animate__animated {
    transition-duration: 1ms !important;
    animation-duration: 1ms !important;
    animation-iteration-count: 1 !important;
  }

  .animate__animated[class*="Out"] {
    opacity: 0;
  }
}

@keyframes headShake {
  0% {
    transform: translateX(0);
  }

  6.5% {
    transform: translateX(-6px)rotateY(-9deg);
  }

  18.5% {
    transform: translateX(5px)rotateY(7deg);
  }

  31.5% {
    transform: translateX(-3px)rotateY(-5deg);
  }

  43.5% {
    transform: translateX(2px)rotateY(3deg);
  }

  50% {
    transform: translateX(0);
  }
}

.animate__headShake {
  animation-name: headShake;
  animation-timing-function: ease-in-out;
}

:root {
  --cc-bg: #fff;
  --cc-text: #2d4156;
  --cc-border-radius: .35em;
  --cc-btn-primary-bg: #2d4156;
  --cc-btn-primary-text: var(--cc-bg);
  --cc-btn-primary-hover-bg: #1d2e38;
  --cc-btn-secondary-bg: #eaeff2;
  --cc-btn-secondary-text: var(--cc-text);
  --cc-btn-secondary-hover-bg: #d8e0e6;
  --cc-btn-border-radius: 4px;
  --cc-toggle-bg-off: #919ea6;
  --cc-toggle-bg-on: var(--cc-btn-primary-bg);
  --cc-toggle-bg-readonly: #d5dee2;
  --cc-toggle-knob-bg: #fff;
  --cc-toggle-knob-icon-color: #ecf2fa;
  --cc-block-text: var(--cc-text);
  --cc-cookie-category-block-bg: #f0f4f7;
  --cc-cookie-category-block-bg-hover: #e9eff4;
  --cc-section-border: #f1f3f5;
  --cc-cookie-table-border: #e9edf2;
  --cc-overlay-bg: #040608d9;
  --cc-webkit-scrollbar-bg: #cfd5db;
  --cc-webkit-scrollbar-bg-hover: #9199a0;
}

.c_darkmode {
  --cc-bg: #181b1d;
  --cc-text: #d8e5ea;
  --cc-btn-primary-bg: #a6c4dd;
  --cc-btn-primary-text: #000;
  --cc-btn-primary-hover-bg: #c2dff7;
  --cc-btn-secondary-bg: #33383c;
  --cc-btn-secondary-text: var(--cc-text);
  --cc-btn-secondary-hover-bg: #3e454a;
  --cc-toggle-bg-off: #667481;
  --cc-toggle-bg-on: var(--cc-btn-primary-bg);
  --cc-toggle-bg-readonly: #454c54;
  --cc-toggle-knob-bg: var(--cc-cookie-category-block-bg);
  --cc-toggle-knob-icon-color: var(--cc-bg);
  --cc-block-text: #b3bfc5;
  --cc-cookie-category-block-bg: #23272a;
  --cc-cookie-category-block-bg-hover: #2b3035;
  --cc-section-border: #292d31;
  --cc-cookie-table-border: #2b3035;
  --cc-webkit-scrollbar-bg: #667481;
  --cc-webkit-scrollbar-bg-hover: #9199a0;
}

.cc_div *, .cc_div :after, .cc_div :before, .cc_div :hover {
  border-radius: unset;
  box-shadow: none;
  box-sizing: border-box;
  color: inherit;
  float: none;
  font-family: inherit;
  font-size: 1em;
  font-style: inherit;
  font-variant: normal;
  font-weight: inherit;
  letter-spacing: unset;
  text-align: left;
  text-transform: none;
  vertical-align: baseline;
  visibility: unset;
  background: none;
  border: none;
  height: auto;
  margin: 0;
  padding: 0;
  line-height: 1.2;
  text-decoration: none;
  transition: none;
  animation: none;
}

.cc_div {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--cc-text);
  text-rendering: optimizelegibility;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  font-size: 16px;
  font-weight: 400;
}

#c-ttl, #s-bl td:before, #s-ttl, .cc_div .b-tl, .cc_div .c-bn {
  font-weight: 600;
}

#cm, #s-bl .act .b-acc, #s-inr, .cc_div .b-tl, .cc_div .c-bl {
  border-radius: .35em;
  border-radius: var(--cc-border-radius);
}

#s-bl .act .b-acc {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.cc_div a, .cc_div button, .cc_div input {
  appearance: none;
  overflow: hidden;
}

.cc_div a {
  border-bottom: 1px solid;
}

.cc_div a:hover {
  border-color: #0000;
  text-decoration: none;
}

#cm-ov, #cs-ov, .c--anim #cm, .c--anim #s-cnt, .c--anim #s-inr {
  transition: visibility .25s linear, opacity .25s, transform .25s !important;
}

.c--anim .c-bn {
  transition: background-color .25s !important;
}

.c--anim #cm.bar.slide, .c--anim #cm.bar.slide + #cm-ov, .c--anim .bar.slide #s-inr, .c--anim .bar.slide + #cs-ov {
  transition: visibility .4s, opacity .4s, transform .4s !important;
}

#cm.bar.slide, .cc_div .bar.slide #s-inr {
  opacity: 1;
  transform: translateX(100%);
}

#cm.bar.top.slide, .cc_div .bar.left.slide #s-inr {
  opacity: 1;
  transform: translateX(-100%);
}

#cm.slide, .cc_div .slide #s-inr {
  transform: translateY(1.6em);
}

#cm.top.slide {
  transform: translateY(-1.6em);
}

#cm.bar.slide {
  transform: translateY(100%);
}

#cm.bar.top.slide {
  transform: translateY(-100%);
}

.show--consent .c--anim #cm, .show--consent .c--anim #cm.bar, .show--settings .c--anim #s-inr, .show--settings .c--anim .bar.slide #s-inr {
  opacity: 1;
  transform: scale(1);
  visibility: visible !important;
}

.show--consent .c--anim #cm.box.middle, .show--consent .c--anim #cm.cloud.middle {
  transform: scale(1)translateY(-50%);
}

.show--settings .c--anim #s-cnt {
  visibility: visible !important;
}

.force--consent.show--consent .c--anim #cm-ov, .show--settings .c--anim #cs-ov {
  opacity: 1 !important;
  visibility: visible !important;
}

#cm {
  background: #fff;
  background: var(--cc-bg);
  opacity: 0;
  visibility: hidden;
  z-index: 1;
  width: 100%;
  max-width: 24.2em;
  padding: 1.1em 1.5em 1.4em;
  font-family: inherit;
  line-height: normal;
  position: fixed;
  bottom: 1.25em;
  right: 1.25em;
  transform: scale(.95);
  box-shadow: 0 .625em 1.875em #02020347;
}

#cc_div #cm {
  display: block !important;
}

#c-ttl {
  margin-bottom: .7em;
  font-size: 1.05em;
}

.cloud #c-ttl {
  margin-top: -.15em;
}

#c-txt {
  font-size: .9em;
  line-height: 1.5em;
}

.cc_div #c-bns {
  justify-content: space-between;
  margin-top: 1.4em;
  display: flex;
}

.cc_div .c-bn {
  background: #eaeff2;
  background: var(--cc-btn-secondary-bg);
  border-radius: 4px;
  border-radius: var(--cc-btn-border-radius);
  color: var(--cc-btn-secondary-text);
  cursor: pointer;
  text-align: center;
  -webkit-user-select: none;
  user-select: none;
  -khtml-user-select: none;
  -o-user-select: none;
  flex: 1;
  padding: 1em 1.7em;
  font-size: .82em;
  display: inline-block;
}

#c-bns button + button, #s-c-bn, #s-cnt button + button {
  float: right;
  margin-left: 1em;
}

#s-cnt #s-rall-bn {
  float: none;
}

#cm .c_link:active, #cm .c_link:hover, #s-c-bn:active, #s-c-bn:hover, #s-cnt button + button:active, #s-cnt button + button:hover {
  background: #d8e0e6;
  background: var(--cc-btn-secondary-hover-bg);
}

#s-cnt {
  visibility: hidden;
  z-index: 101;
  width: 100%;
  height: 100%;
  display: table;
  position: fixed;
  top: 0;
  left: 0;
}

#s-bl {
  outline: none;
}

#s-bl .title {
  margin-top: 1.4em;
}

#s-bl .b-bn, #s-bl .title:first-child {
  margin-top: 0;
}

#s-bl .b-acc .p {
  margin-top: 0;
  padding: 1em;
}

#s-cnt .b-bn .b-tl {
  background: none;
  width: 100%;
  padding: 1.3em 6.4em 1.3em 2.7em;
  font-family: inherit;
  font-size: .95em;
  transition: background-color .25s;
  display: block;
  position: relative;
}

#s-cnt .b-bn .b-tl.exp {
  cursor: pointer;
}

#s-cnt .act .b-bn .b-tl {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

#s-cnt .b-bn .b-tl:active, #s-cnt .b-bn .b-tl:hover {
  background: #e9eff4;
  background: var(--cc-cookie-category-block-bg-hover);
}

#s-bl .b-bn {
  position: relative;
}

#s-bl .c-bl {
  border: 1px solid #f1f3f5;
  border: 1px solid var(--cc-section-border);
  margin-bottom: .5em;
  padding: 1em;
  transition: background-color .25s;
}

#s-bl .c-bl:hover {
  background: #f0f4f7;
  background: var(--cc-cookie-category-block-bg);
}

#s-bl .c-bl:last-child {
  margin-bottom: .5em;
}

#s-bl .c-bl:first-child {
  border: none;
  margin-top: 0;
  margin-bottom: 2em;
  padding: 0;
  transition: none;
}

#s-bl .c-bl:not(.b-ex):first-child:hover {
  background: none;
  background: unset;
}

#s-bl .c-bl.b-ex {
  background: #f0f4f7;
  background: var(--cc-cookie-category-block-bg);
  border: none;
  padding: 0;
  transition: none;
}

#s-bl .c-bl.b-ex + .c-bl {
  margin-top: 2em;
}

#s-bl .c-bl.b-ex + .c-bl.b-ex {
  margin-top: 0;
}

#s-bl .c-bl.b-ex:first-child {
  margin-bottom: .5em;
}

#s-bl .b-acc {
  max-height: 0;
  margin-bottom: 0;
  padding-top: 0;
  display: none;
  overflow: hidden;
}

#s-bl .act .b-acc {
  max-height: 100%;
  display: block;
  overflow: hidden;
}

#s-cnt .p {
  color: var(--cc-block-text);
  margin-top: .85em;
  font-size: .9em;
  line-height: 1.5em;
}

.cc_div .b-tg .c-tgl:disabled {
  cursor: not-allowed;
}

#c-vln {
  vertical-align: middle;
  display: table-cell;
  position: relative;
}

#cs {
  width: 100%;
  padding: 0 1.7em;
  position: fixed;
  inset: 0;
}

#cs, #s-inr {
  height: 100%;
}

#s-inr {
  opacity: 0;
  visibility: hidden;
  max-width: 45em;
  margin: 0 auto;
  padding-top: 4.75em;
  padding-bottom: 4.75em;
  position: relative;
  overflow: hidden;
  transform: scale(.96);
  box-shadow: 0 13px 27px -5px #03060942;
}

#s-bns, #s-hdr, #s-inr {
  background: #fff;
  background: var(--cc-bg);
}

#s-bl {
  overflow-x: hidden;
  overflow-y: auto;
  overflow-y: overlay;
  width: 100%;
  height: 100%;
  padding: 1.3em 1.8em;
  display: block;
}

#s-bns {
  border-top: 1px solid #f1f3f5;
  border-top: 1px solid var(--cc-section-border);
  height: 4.75em;
  padding: 1em 1.8em;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.cc_div .cc-link {
  border-bottom: 1px solid #2d4156;
  border-bottom: 1px solid var(--cc-btn-primary-bg);
  color: var(--cc-btn-primary-bg);
  cursor: pointer;
  padding-bottom: 0;
  font-weight: 600;
  text-decoration: none;
  display: inline;
}

.cc_div .cc-link:active, .cc_div .cc-link:hover {
  border-color: #0000;
}

#c-bns button:first-child, #s-bns button:first-child {
  background: #2d4156;
  background: var(--cc-btn-primary-bg);
  color: var(--cc-btn-primary-text);
}

#c-bns.swap button:first-child {
  background: #eaeff2;
  background: var(--cc-btn-secondary-bg);
  color: var(--cc-btn-secondary-text);
}

#c-bns.swap button:last-child {
  background: #2d4156;
  background: var(--cc-btn-primary-bg);
  color: var(--cc-btn-primary-text);
}

.cc_div .b-tg .c-tgl:checked ~ .c-tg {
  background: #2d4156;
  background: var(--cc-toggle-bg-on);
}

#c-bns button:first-child:active, #c-bns button:first-child:hover, #c-bns.swap button:last-child:active, #c-bns.swap button:last-child:hover, #s-bns button:first-child:active, #s-bns button:first-child:hover {
  background: #1d2e38;
  background: var(--cc-btn-primary-hover-bg);
}

#c-bns.swap button:first-child:active, #c-bns.swap button:first-child:hover {
  background: #d8e0e6;
  background: var(--cc-btn-secondary-hover-bg);
}

#s-hdr {
  border-bottom: 1px solid #f1f3f5;
  border-bottom: 1px solid var(--cc-section-border);
  z-index: 2;
  width: 100%;
  height: 4.75em;
  padding: 0 1.8em;
  display: table;
  position: absolute;
  top: 0;
}

#s-hdr, #s-ttl {
  vertical-align: middle;
}

#s-ttl {
  font-size: 1em;
  display: table-cell;
}

#s-c-bn {
  width: 1.7em;
  height: 1.7em;
  margin: 0;
  padding: 0;
  font-size: 1.45em;
  font-weight: 400;
  position: relative;
  overflow: hidden;
}

#s-c-bnc {
  vertical-align: middle;
  display: table-cell;
}

.cc_div span.t-lb {
  opacity: 0;
  pointer-events: none;
  z-index: -1;
  position: absolute;
  top: 0;
  overflow: hidden;
}

#c_policy__text {
  height: 31.25em;
  margin-top: 1.25em;
  overflow-y: auto;
}

#c-s-in {
  height: calc(100% - 2.5em);
  max-height: 37.5em;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

@media screen and (width >= 688px) {
  #s-bl::-webkit-scrollbar {
    background: none;
    border-radius: 0 .25em .25em 0;
    width: .9em;
    height: 100%;
  }

  #s-bl::-webkit-scrollbar-thumb {
    background: #cfd5db;
    background: var(--cc-webkit-scrollbar-bg);
    border: .25em solid #fff;
    border: .25em solid var(--cc-bg);
    border-radius: 100em;
  }

  #s-bl::-webkit-scrollbar-thumb:hover {
    background: #9199a0;
    background: var(--cc-webkit-scrollbar-bg-hover);
  }

  #s-bl::-webkit-scrollbar-button {
    width: 10px;
    height: 5px;
  }
}

.cc_div .b-tg {
  -webkit-user-select: none;
  user-select: none;
  vertical-align: middle;
  margin: auto;
  display: inline-block;
  bottom: 0;
  right: 1.2em;
}

.cc_div .b-tg, .cc_div .b-tg .c-tgl {
  cursor: pointer;
  position: absolute;
  top: 0;
}

.cc_div .b-tg .c-tgl {
  border: 0;
  margin: 0;
  display: block;
  left: 0;
}

.cc_div .b-tg .c-tg {
  background: #919ea6;
  background: var(--cc-toggle-bg-off);
  pointer-events: none;
  transition: background-color .25s, box-shadow .25s;
  position: absolute;
}

.cc_div .b-tg, .cc_div .b-tg .c-tg, .cc_div .b-tg .c-tgl, .cc_div span.t-lb {
  border-radius: 4em;
  width: 3.4em;
  height: 1.5em;
}

.cc_div .b-tg .c-tg.c-ro {
  cursor: not-allowed;
}

.cc_div .b-tg .c-tgl ~ .c-tg.c-ro {
  background: #d5dee2;
  background: var(--cc-toggle-bg-readonly);
}

.cc_div .b-tg .c-tgl ~ .c-tg.c-ro:after {
  box-shadow: none;
}

.cc_div .b-tg .c-tg:after {
  background: #fff;
  background: var(--cc-toggle-knob-bg);
  box-sizing: content-box;
  content: "";
  border: none;
  border-radius: 100%;
  width: 1.25em;
  height: 1.25em;
  transition: transform .25s;
  display: block;
  position: relative;
  top: .125em;
  left: .125em;
  box-shadow: 0 1px 2px #1820235c;
}

.cc_div .b-tg .c-tgl:checked ~ .c-tg:after {
  transform: translateX(1.9em);
}

#s-bl table, #s-bl td, #s-bl th {
  border: none;
}

#s-bl tbody tr {
  transition: background-color .25s;
}

#s-bl tbody tr:hover {
  background: #e9eff4;
  background: var(--cc-cookie-category-block-bg-hover);
}

#s-bl table {
  border-collapse: collapse;
  text-align: left;
  width: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

#s-bl td, #s-bl th {
  text-align: left;
  vertical-align: top;
  padding: .8em .625em .8em 1.2em;
  font-size: .8em;
}

#s-bl th {
  padding: 1.2em;
  font-family: inherit;
}

#s-bl thead tr:first-child {
  border-bottom: 1px solid #e9edf2;
  border-bottom: 1px solid var(--cc-cookie-table-border);
}

.force--consent #cs, .force--consent #s-cnt {
  width: 100vw;
}

#cm-ov, #cs-ov {
  background: #040608d9;
  background: var(--cc-overlay-bg);
  opacity: 0;
  visibility: hidden;
  transition: none;
  display: none;
  position: fixed;
  inset: 0;
}

.c--anim #cs-ov, .force--consent .c--anim #cm-ov, .force--consent.show--consent #cm-ov, .show--settings #cs-ov {
  display: block;
}

#cs-ov {
  z-index: 2;
}

.force--consent .cc_div {
  visibility: hidden;
  width: 100vw;
  transition: visibility .25s linear;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
}

.force--consent.show--consent .c--anim .cc_div, .force--consent.show--settings .c--anim .cc_div {
  visibility: visible;
}

.force--consent #cm {
  position: absolute;
}

.force--consent #cm.bar {
  width: 100vw;
  max-width: 100vw;
}

html.force--consent.show--consent {
  overflow-y: hidden !important;
}

html.force--consent.show--consent, html.force--consent.show--consent body {
  height: auto !important;
  overflow-x: hidden !important;
}

.cc_div .act .b-bn .exp:before, .cc_div .b-bn .exp:before {
  border: solid #2d4156;
  border: solid var(--cc-btn-secondary-text);
  content: "";
  border-width: 0 2px 2px 0;
  margin-right: 15px;
  padding: .2em;
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 1.2em;
  transform: translateY(-50%)rotate(45deg);
}

.cc_div .act .b-bn .b-tl:before {
  transform: translateY(-20%)rotate(225deg);
}

.cc_div .on-i:before {
  border: solid #ecf2fa;
  border: solid var(--cc-toggle-knob-icon-color);
  border-width: 0 2px 2px 0;
  padding: .1em .1em .45em;
  display: inline-block;
  top: .37em;
  left: .75em;
}

#s-c-bn:after, #s-c-bn:before, .cc_div .on-i:before {
  content: "";
  margin: 0 auto;
  position: absolute;
  transform: rotate(45deg);
}

#s-c-bn:after, #s-c-bn:before {
  background: #2d4156;
  background: var(--cc-btn-secondary-text);
  border-radius: 1em;
  width: 1.5px;
  height: .6em;
  top: .58em;
  left: .82em;
}

#s-c-bn:after {
  transform: rotate(-45deg);
}

.cc_div .off-i, .cc_div .on-i {
  text-align: center;
  width: 50%;
  height: 100%;
  transition: opacity .15s;
  display: block;
  position: absolute;
  right: 0;
}

.cc_div .on-i {
  opacity: 0;
  left: 0;
}

.cc_div .off-i:after, .cc_div .off-i:before {
  background: #ecf2fa;
  background: var(--cc-toggle-knob-icon-color);
  content: " ";
  transform-origin: center;
  width: .09375em;
  height: .7em;
  margin: 0 auto;
  display: block;
  position: absolute;
  top: .42em;
  right: .8em;
}

.cc_div .off-i:before {
  transform: rotate(45deg);
}

.cc_div .off-i:after {
  transform: rotate(-45deg);
}

.cc_div .b-tg .c-tgl:checked ~ .c-tg .on-i {
  opacity: 1;
}

.cc_div .b-tg .c-tgl:checked ~ .c-tg .off-i {
  opacity: 0;
}

#cm.box.middle, #cm.cloud.middle {
  top: 50%;
  bottom: auto;
  transform: translateY(-37%);
}

#cm.box.middle.zoom, #cm.cloud.middle.zoom {
  transform: scale(.95)translateY(-50%);
}

#cm.box.center, #cm.cloud {
  margin: 0 auto;
  left: 1em;
  right: 1em;
}

#cm.cloud {
  text-align: center;
  width: unset;
  max-width: 50em;
  padding: 1.3em 2em;
  overflow: hidden;
}

.cc_div .cloud #c-inr {
  width: 100%;
  display: table;
}

.cc_div .cloud #c-inr-i {
  vertical-align: top;
  width: 70%;
  padding-right: 2.4em;
  display: table-cell;
}

.cc_div .cloud #c-txt {
  font-size: .85em;
}

.cc_div .cloud #c-bns {
  vertical-align: middle;
  min-width: 170px;
  display: table-cell;
}

#cm.cloud .c-bn {
  width: 100%;
  margin: .625em 0 0;
}

#cm.cloud .c-bn:first-child {
  margin: 0;
}

#cm.cloud.left {
  margin-right: 1.25em;
}

#cm.cloud.right {
  margin-left: 1.25em;
}

#cm.bar {
  border-radius: 0;
  width: 100%;
  max-width: 100%;
  padding: 2em;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
}

#cm.bar #c-inr {
  max-width: 32em;
  margin: 0 auto;
}

#cm.bar #c-bns {
  max-width: 33.75em;
}

#cm.bar #cs {
  padding: 0;
}

.cc_div .bar #c-s-in {
  height: 100%;
  max-height: 100%;
  top: 0;
  transform: none;
}

.cc_div .bar #s-bl, .cc_div .bar #s-bns, .cc_div .bar #s-hdr {
  padding-left: 1.6em;
  padding-right: 1.6em;
}

.cc_div .bar #cs {
  padding: 0;
}

.cc_div .bar #s-inr {
  border-radius: 0;
  max-width: 32em;
  margin: 0 0 0 auto;
}

.cc_div .bar.left #s-inr {
  margin-left: 0;
  margin-right: auto;
}

.cc_div .bar #s-bl table, .cc_div .bar #s-bl tbody, .cc_div .bar #s-bl td, .cc_div .bar #s-bl th, .cc_div .bar #s-bl thead, .cc_div .bar #s-bl tr, .cc_div .bar #s-cnt {
  display: block;
}

.cc_div .bar #s-bl thead tr {
  position: absolute;
  top: -9999px;
  left: -9999px;
}

.cc_div .bar #s-bl tr {
  border-top: 1px solid #e9edf2;
  border-top: 1px solid var(--cc-cookie-table-border);
}

.cc_div .bar #s-bl td {
  border: none;
  padding-left: 35%;
  position: relative;
}

.cc_div .bar #s-bl td:before {
  color: var(--cc-text);
  content: attr(data-column);
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-right: .625em;
  position: absolute;
  left: 1em;
  overflow: hidden;
}

#cm.top {
  top: 1.25em;
  bottom: auto;
}

#cm.left {
  left: 1.25em;
  right: auto;
}

#cm.right {
  left: auto;
  right: 1.25em;
}

#cm.bar.left, #cm.bar.right {
  left: 0;
  right: 0;
}

#cm.bar.top {
  top: 0;
}

@media screen and (width <= 688px) {
  #cm, #cm.cloud, #cm.left, #cm.right {
    width: auto;
    max-width: 100%;
    margin: 0;
    display: block;
    bottom: 1em;
    left: 1em;
    right: 1em;
    padding: 1.4em !important;
  }

  .force--consent #cm, .force--consent #cm.cloud {
    width: auto;
    max-width: 100vw;
  }

  #cm.top {
    top: 1em;
    bottom: auto;
  }

  #cm.bottom {
    top: auto;
    bottom: 1em;
  }

  #cm.bar.bottom {
    bottom: 0;
  }

  #cm.cloud .c-bn {
    font-size: .85em;
  }

  #s-bns, .cc_div .bar #s-bns {
    padding: 1em 1.3em;
  }

  .cc_div .bar #s-inr {
    width: 100%;
    max-width: 100%;
  }

  .cc_div .cloud #c-inr-i {
    padding-right: 0;
  }

  #cs {
    border-radius: 0;
    padding: 0;
  }

  #c-s-in {
    height: 100%;
    max-height: 100%;
    top: 0;
    transform: none;
  }

  .cc_div .b-tg {
    right: 1.1em;
    transform: scale(1.1);
  }

  #s-inr {
    border-radius: 0;
    margin: 0;
    padding-bottom: 7.9em;
  }

  #s-bns {
    height: 7.9em;
  }

  #s-bl, .cc_div .bar #s-bl {
    padding: 1.3em;
  }

  #s-hdr, .cc_div .bar #s-hdr {
    padding: 0 1.3em;
  }

  #s-bl table {
    width: 100%;
  }

  #s-inr.bns-t {
    padding-bottom: 10.5em;
  }

  .bns-t #s-bns {
    height: 10.5em;
  }

  .cc_div .bns-t .c-bn {
    padding: .9em 1.6em;
    font-size: .83em;
  }

  #s-cnt .b-bn .b-tl {
    padding-top: 1.2em;
    padding-bottom: 1.2em;
  }

  #s-bl table, #s-bl tbody, #s-bl td, #s-bl th, #s-bl thead, #s-bl tr, #s-cnt {
    display: block;
  }

  #s-bl thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }

  #s-bl tr {
    border-top: 1px solid #e9edf2;
    border-top: 1px solid var(--cc-cookie-table-border);
  }

  #s-bl td {
    border: none;
    padding-left: 35%;
    position: relative;
  }

  #s-bl td:before {
    color: var(--cc-text);
    content: attr(data-column);
    text-overflow: ellipsis;
    white-space: nowrap;
    padding-right: .625em;
    position: absolute;
    left: 1em;
    overflow: hidden;
  }

  #cm .c-bn, .cc_div .c-bn {
    width: 100%;
    margin-right: 0;
  }

  #s-cnt #s-rall-bn {
    margin-left: 0;
  }

  .cc_div #c-bns {
    flex-direction: column;
  }

  #c-bns button + button, #s-cnt button + button {
    float: unset;
    margin-top: .625em;
    margin-left: 0;
  }

  #cm.box, #cm.cloud {
    width: auto;
    left: 1em;
    right: 1em;
  }

  #cm.cloud.left, #cm.cloud.right {
    margin: 0;
  }

  .cc_div .cloud #c-bns, .cc_div .cloud #c-inr, .cc_div .cloud #c-inr-i {
    min-width: unset;
    width: auto;
    display: block;
  }

  .cc_div .cloud #c-txt {
    font-size: .9em;
  }

  .cc_div .cloud #c-bns {
    margin-top: 1.625em;
  }
}

.cc_div.ie #c-vln {
  height: 100%;
  padding-top: 5.62em;
}

.cc_div.ie .bar #c-vln {
  padding-top: 0;
}

.cc_div.ie #cs {
  max-height: 37.5em;
  margin-top: -5.625em;
  position: relative;
  top: 0;
}

.cc_div.ie .bar #cs {
  max-height: 100%;
  margin-top: 0;
}

.cc_div.ie #cm {
  border: 1px solid #dee6e9;
}

.cc_div.ie #c-s-in {
  top: 0;
}

.cc_div.ie .b-tg {
  margin-bottom: .7em;
  padding-left: 1em;
}

.cc_div.ie .b-tg .c-tgl:checked ~ .c-tg:after {
  left: 1.95em;
}

.cc_div.ie #s-bl table {
  overflow: auto;
}

.cc_div.ie .b-tg .c-tg {
  display: none;
}

.cc_div.ie .b-tg .c-tgl {
  vertical-align: middle;
  height: auto;
  margin-bottom: .2em;
  display: inline-block;
  position: relative;
}

.cc_div.ie #s-cnt .b-bn .b-tl {
  padding: 1.3em 6.4em 1.3em 1.4em;
}

.cc_div.ie .bar #s-bl td:before {
  display: none;
}

.cc_div.ie .bar #s-bl td {
  padding: .8em .625em .8em 1.2em;
}

.cc_div.ie .bar #s-bl thead tr {
  position: relative;
}

.cc_div.ie .b-tg .t-lb {
  filter: alpha(opacity= 0);
}

.cc_div.ie #cm-ov, .cc_div.ie #cs-ov {
  filter: alpha(opacity= 80);
}

.eLOaVq_gameInfo {
  opacity: .95;
  font-size: 1.125rem;
  font-weight: 600;
}

.eLOaVq_timer {
  position: absolute;
  inset-block-start: calc(env(safe-area-inset-top)  + 1rem);
  inset-inline-end: calc(env(safe-area-inset-right)  + 1rem);
}

.eLOaVq_points {
  position: absolute;
  inset-block-start: calc(env(safe-area-inset-top)  + (80 / 16 * 1rem));
  inset-inline: 0;
}

.eLOaVq_afterForm {
  padding-block-start: var(--grid-size);
  position: relative;
}

.MySpwq_timer {
  align-items: center;
  gap: calc(var(--grid-size) / 2);
  display: flex;
}

.MySpwq_text {
  font-size: 1.375rem;
  font-weight: normal;
  line-height: 1.25;
}

._1GXZdW_points {
  text-align: center;
  text-transform: uppercase;
  opacity: .7;
  flex-direction: column;
  margin: 0;
  font-weight: 600;
  display: flex;
}

._1GXZdW_number {
  font-size: 2.5rem;
  line-height: 1;
}

.P071yW_message {
  flex-wrap: wrap;
  align-items: center;
  gap: 8px;
  inline-size: 100%;
  display: flex;
}

.P071yW_message[hidden] {
  display: none;
}

.P071yW_text {
  opacity: .95;
  flex: 1;
  font-size: 1rem;
}

.P071yW_arrow {
  transform: rotate(243deg)scaleX(-1);
}

._1PPtcW_main {
  block-size: 100%;
  inline-size: min(35rem, 100vw);
  margin: auto;
  padding-block-start: calc(env(safe-area-inset-top)  + 1rem);
  padding-block-end: calc(env(safe-area-inset-bottom)  + 1rem);
  padding-inline-start: calc(env(safe-area-inset-left)  + 1rem);
  padding-inline-end: calc(env(safe-area-inset-right)  + 1rem);
  position: relative;
}

.k9n4FW_root {
  text-align: center;
  align-content: center;
  place-items: center;
  display: grid;
}

.k9n4FW_root * {
  opacity: 0;
  transition: opacity .2s linear .3s;
}

.k9n4FW_visible * {
  opacity: 1;
}

.k9n4FW_icon {
  transform-origin: center;
  animation: 1s infinite both k9n4FW_rotate;
}

.k9n4FW_text {
  text-transform: uppercase;
  font-weight: bold;
}

.k9n4FW_large {
  font-size: 1.875rem;
  display: block;
}

@keyframes k9n4FW_rotate {
  to {
    transform: rotate(1turn);
  }
}

button._7VRukW_button {
  block-size: initial;
  padding: calc(var(--grid-size) * 2);
  position: absolute;
  inset-block-start: calc(var(--grid-size) * .5);
  inset-inline-start: calc(var(--grid-size) * .5);
}

._7VRukW_dialogHeading {
  margin: 0;
  font-size: 1.25rem;
}

._7VRukW_dialogContent details {
  margin-block-end: calc(var(--grid-size) * 2);
  font-size: 1.125rem;
}

._7VRukW_dialogContent summary {
  cursor: pointer;
  font-weight: bold;
}

html:has(dialog[open]) {
  overflow: hidden;
}

.ZdGOpq_dialog {
  font-family: var(--font-body-copy);
  border-radius: calc(var(--grid-size) / 2);
  inline-size: min(calc(100vw - var(--grid-size) * 4), calc(600 / 16) * 1rem);
  min-inline-size: min(calc(100vw - var(--grid-size) * 4), calc(600 / 16) * 1rem);
  opacity: 0;
  border: none;
  block-size: min(95dvb, 90vh);
  padding: 0;
  line-height: 1.4;
  transition: transform .24s cubic-bezier(.18, .89, .32, 1.28), opacity .32s;
  transform: matrix(.8, 0, 0, .5, 0, 0);
  box-shadow: 0 21px 36px -13px #0000004a;
}

.ZdGOpq_dialog[open] {
  grid-template-rows: auto 1fr;
  display: grid;
}

.ZdGOpq_header {
  background: #eee;
  border-block-end: 1px solid #dfdfdf;
  grid-template-columns: 1fr auto;
  align-items: center;
  min-block-size: 40px;
  display: grid;
  overflow: hidden;
}

button.ZdGOpq_closeButton {
  block-size: unset;
  color: initial;
  padding: calc(var(--grid-size) * 2);
  margin: calc(var(--grid-size) * 2 * -1);
}

.ZdGOpq_content {
  overflow-y: auto;
}

.ZdGOpq_header, .ZdGOpq_content {
  padding: calc(var(--grid-size) * 2);
}

.ZdGOpq_dialog::backdrop {
  opacity: 0;
}

.ZdGOpq_visible {
  opacity: 1;
  transform: matrix(1, 0, 0, 1, 0, 0);
}

.ZdGOpq_hidden {
  opacity: 0;
  transition-duration: .4s;
}

.IK595a_gameEnd {
  background-color: var(--fg-color);
  color: #000;
  text-align: center;
  z-index: 1;
  inline-size: 100vw;
  position: fixed;
  inset-block-start: 0;
  inset-inline-start: 0;
}

.IK595a_heading {
  margin: 0;
  font-size: 1.875rem;
  line-height: 1.3;
}

.IK595a_shareHeading {
  margin: 0 0 calc(var(--grid-size) * 2.5);
  font-size: 1.125rem;
  font-weight: bold;
}

.IK595a_lineBreak {
  display: block;
}

.IK595a_icon {
  block-size: calc(var(--grid-size) * 8);
  margin-block-start: calc((80 / 16 * 1rem)  + env(safe-area-inset-top));
}

.IK595a_share {
  margin-block-start: calc(var(--grid-size) * 6);
  margin-block-end: calc(var(--grid-size) * 11);
}

.IK595a_actions {
  place-content: center;
  gap: var(--grid-size);
  margin-block-start: calc(var(--grid-size) * 11);
  display: grid;
}

.ujxFdG_heading {
  margin: 0 0 calc(var(--grid-size) * 2.5);
  font-size: 1.125rem;
  font-weight: bold;
}

.ujxFdG_list {
  justify-content: center;
  gap: calc(var(--grid-size) * 2);
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
}

.ujxFdG_icon {
  display: block;
}

._7jhptW_button {
  --overlay-opacity: 0;
  --_bg-color: var(--bg-color-alert);
  --_fg-color: var(--fg-color);
  --_border-color: transparent;
  color: var(--_fg-color);
  padding: 0 calc(var(--grid-size) * 2);
  block-size: calc(var(--grid-size) * 6);
  background: linear-gradient(rgba(0, 0, 0, var(--overlay-opacity)) 0 100%);
  background-color: var(--_bg-color);
  cursor: pointer;
  border: 3px solid var(--_border-color);
  gap: var(--grid-size);
  min-inline-size: calc(var(--grid-size) * 22);
  align-items: center;
  font-family: inherit;
  font-size: 1.125rem;
  font-weight: bold;
  line-height: 1.33;
  display: flex;
}

._7jhptW_button:active {
  --overlay-opacity: .15;
}

._7jhptW_outlined {
  --_bg-color: var(--fg-color);
  --_fg-color: var(--bg-color-alert);
  --_border-color: var(--bg-color-alert);
}

._7jhptW_transparent {
  --_bg-color: transparent;
  min-inline-size: unset;
  border: none;
}

._7jhptW_icon > svg {
  display: block;
}

/*# sourceMappingURL=index.de3eb4a0.css.map */
