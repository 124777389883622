:root {
  --font-family: Futura, Futura-Medium, 'Futura Medium', 'Century Gothic', CenturyGothic,
    'Apple Gothic', AppleGothic, 'URW Gothic L', 'Avant Garde', sans-serif;
  --font-body-copy: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;

  --bg-color-ok: #4e7b26;
  --bg-color-warn: #d14900;
  --bg-color-alert: #c61023;

  --bg-color: var(--bg-color-ok);
  --fg-color: #fff;

  --grid-size: calc(8 / 16 * 1rem);

  /* animate.css */
  --animate-duration: 400ms;
  --animate-delay: 0.2s;
}

* {
  box-sizing: border-box;
}

html,
body,
.full-height {
  block-size: calc(100% + env(safe-area-inset-top) + env(safe-area-inset-bottom));
}

html {
  background-color: var(--bg-color);
  color: var(--fg-color);
}

body {
  margin: 0;
  font-family: var(--font-family);
  background-color: var(--bg-color);
  transition: background-color 2s;
}

a {
  color: var(--bg-color-warn);
}

.visually-hidden {
  position: absolute !important;
  inline-size: 1px !important;
  block-size: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}

.no-transition {
  transition: none;
}
